body, div, header, main, label, span, ul, li, h1, input, textarea, button {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body, html, #root{
  width: 100%;
  height: 100%;
  margin: 0;
}

.mrow2 {
  width: 100%;
  background: white;
  border-bottom: 1px solid gainsboro;
}

.mrow2-button {
  padding: 2px 10px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 5px 0px 5px 5px;
}

.mrow2-button:hover {
  filter: contrast(150%);
}

.green {
  background: #47AD16;
  padding:5px 2vw;
  font-weight: bold;
}

.gray {
  background: #999;
  padding:5px 2vw;
  font-weight: bold;
}

.red {
  background: rgb(245, 91, 91);
  padding:5px 2vw;
  font-weight: bold;
}

.blue {
  background: #556DAE;
  padding:5px 30px;
  font-weight: bold;
}

.orange {
  background: #d56c08;
  padding:5px 30px;
  font-weight: bold;
}

.outarea {
  background: #faf3e8;
  padding: 2px;
}

.vertically {
  margin: 2px;
  -webkit-box-shadow: 1px 0px 2px;
          box-shadow: 1px 0px 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
    border: 1px solid #eee;
    border-top: 3px solid #642863;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    background-color: #f9f7f3;
    margin-bottom: 0.5em;
    padding: 0 0.5em 0.5em;
}

.marea-label {
  color: #888787;
  margin: 8px;
}

.marea-label span {
  color: red;
  font-size: 80%;
  margin-left: 8px;
}

.react-calendar{
  min-width:150px;
  width:200px;
}
.react-calendar__navigation{
  height: 30px;
  margin-bottom: 0;
}
.react-calendar__navigation button{
  min-width:auto;
  padding:10px;
}

.react-calendar__tile,
.react-calendar__year-view .react-calendar__tile, 
.react-calendar__decade-view .react-calendar__tile, 
.react-calendar__century-view .react-calendar__tile{
  padding: 5px;
}

.byside{
  display: inline-flex;
  margin: 8px;
  flex-direction: row;
  position: relative;
}


.herf{
  width: 100px;
}

.textarea{
  width:400px ;
}



.textdate-nowDate{
  background-color: aquamarine !important;
}

.text{
  background: #fff;
  font-size: 16px;
  min-height: 1em;
  padding: 0.2em 0.5em;
  line-height: 1.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.textnumber-text{
  background: #fff;
  font-size: 16px;
  min-height: 1em;
  padding: 0.2em 0.5em;
  line-height: 1.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}

.textdate-text{
  background: #fff;
  font-size: 16px;
  min-height: 1em;
  padding: 0.2em 0.5em;
  line-height: 1.5em;
  border-radius: 0;
  width:120px ;
}

.datepicker-label {
  margin: 8px;
}

/* ーーーーーーーーーー　ログインページ　－－－－－－－－－－－－－－－－－－ */
.login_outer{
  background:radial-gradient(rgb(253, 233, 227),90%, rgb(223, 178, 247));
}

.login_title{
  margin-bottom:70px;
  font-size: 3.5rem;
  font-weight: bolder;
  border-bottom:5px dashed #642863;  
  /* border-top:5px dashed #556DAE;   */
  padding:0px 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.login_inner{
  border: 3px solid #642863;
  background-color: #fffaff;
  padding: 50px;
  border-radius: 10px;
  width: 400px;
}

.login_text{
  border:1px solid #642863;
  border-radius: 10px;
  margin-bottom:30px;
  line-height: 2rem;
  padding: 0 5px;  
}

/* .login_btn{
  border-radius: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: #747;
  color: #fff;
  border:1px solid #747;
  font-weight:bold; 
  margin: 0 8px;
} */

.login_WQC{
  margin-bottom:40px;
  background-color: #595;
}

.login_btn:hover{
  background-color: #efefef;
  color:  #747;
}


.outer-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.inner-container {
  position: absolute;
  left: 0;
  right: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.inner-container::-webkit-scrollbar {
  display: none;
}

.menu-container {
  width: 100%;
}

/*ーーーーーーーーーーー react-table カスタマイズーーーーーーーーーーーーーー*/

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-th{
  background-color: #eaeaea;
  text-align: left;
  font-weight: bold;
}