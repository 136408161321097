html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  font-family: 'メイリオ', 'Meiryo', 'ＭＳ ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Tahoma', 'Helvetica', 'Microsoft YaHei', sans-serif;
}

body {
  line-height: 1;
  min-height: 100vh;
  background: #E6E6E6;

}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

button {
  box-sizing: border-box;
  padding: 0px;
  font-family: 'メイリオ', 'Meiryo', 'ＭＳ ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
}

textarea {
  font-family: 'メイリオ', 'Meiryo', 'ＭＳ ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
}

/*--------------- ここまではリセットcssです ----------------------*/

/*--------------- 多言語Font対応　考え中 ----------------------*/
:lang(cn) {
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif !important;
}

:lang(zh) {
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif !important;
}

:lang(ja) {
  font-family: 'メイリオ', 'Meiryo', 'ＭＳ ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif !important;
}

:lang(en) {
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "Meiryo", sans-serif !important;
}

/*-----------------chrome scroll bar--------------------*/
/* ::-webkit-scrollbar : scroll 全体
::-webkit-scrollbar-thumb  scrollの移動するBarのこと
::-webkit-scrollbar-track  scrollの軌道
::-webkit-scrollbar-button scrollの軌道両端の調整ボタン
::-webkit-scrollbar-track-piece scrollの軌道内部
::-webkit-scrollbar-corner scrollの交差点
::-webkit-resizer 不明？？
 */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.8rem;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: var(--scrollTrackColor);
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--scrollThumnColor);
}

::-webkit-scrollbar-corner {
  background-color: var(--scrollTrackColor);
}

/*-----------------firefox scroll bar--------------------*/
div {
  scrollbar-color: var(--scrollThumnColor) var(--scrollTrackColor);
  scrollbar-width: thin;
}

/*-----------------nav css--------------------*/
div#ui-side-bar {
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  transition: 0.2s;
  width: var(--leftMenuWidth);
  padding-top: var(--topMenuHeight);
}

/*define param*/
:root {
  --scrollThumnColor: rgb(119, 68, 119);
  --scrollTrackColor: #F5F5F5;
  --topMenuHeight: 90px;
  --leftMenuWidth: 200px;
}