
.spinner_edi_loading {
  margin:50px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-Index:999;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(255,255,255,0.5);
}
 
.spinner_edi_loading > div {
  background-color: rgba(119, 68, 119, 0.774);
  margin: 6px;
  height: 200px;
  width: 25px;
  display: inline-block;
  top:0;left:0;right:0;bottom:0;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}
 
.spinner_edi_loading .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
 
.spinner_edi_loading .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
 
.spinner_edi_loading .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
 
.spinner_edi_loading .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.spinner_edi_loading .rect6 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
 
@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.35) } 
  20% { -webkit-transform: scaleY(1.0) }
}
 
@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.35);
    -webkit-transform: scaleY(0.35);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}